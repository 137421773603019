<template>
    <div>
        <el-container>
            <el-header style="padding: 0" height="100%">
                <vheadere class="hidden-sm-and-down"></vheadere>
                <ivheaderae class="hidden-md-and-up"></ivheaderae>
            </el-header>
            <el-main style="padding: 0">
              <div class="Q3-container">
                 <div class="w">
                    <div class="funA-top">
                      <div class="content">
                        <div class="title">
                          <img src="../../assets/images/Q/Q6/header-title-en.jpg" alt="">
                        </div>
                        <div class="list hidden-sm-and-down">
                          <ul>
                            <li @click="btn1">Homepage</li>
                            <li @click="btn2">Events</li>
                            <li @click="btn3">Organizers</li>
                          </ul>
                          <el-popover
                            placement="bottom"
                            width="300"
                            trigger="hover"
                            content="Only users certified as organizers can create an event">
                            <div class="btn" slot="reference" @click="btn4">
                              <i class="el-icon-s-flag"></i>
                              <span>Create Events for Free</span>
                            </div>
                          </el-popover>
                        </div>
                        <div class="phone-list-btn hidden-md-and-up">
                          <button @click="btn1">Homepage</button>
                          <button @click="btn2">Events</button>
                          <button @click="btn3">Organizers</button>
                          <button @click="btn4phone">
                            <i class="el-icon-s-flag"></i>
                            <span>Create Events for Free</span>
                          </button>
                        </div>
                      </div>
                    </div>
                 </div>
                <div class="select-title">
                  <div class="w">
                    <div class="select-list">
                      <ul class="hidden-sm-and-down">
                        <span>Sort:</span>
                        <li @click="selType(-1)" :class="selNum == -1?'is-active':''">All</li>
                        <li v-for="(item,index) in selectData" :key="index" @click="selType(index)" :class="selNum === index?'is-active':''">{{item.TWO}}</li>
                      </ul>
                      <div class="m-selBtn hidden-md-and-up" @click="selListOpen">
                        <i class="el-icon-arrow-up" :class="selOpen?'transform':''"></i>
                        Sort selection<span> {{selNum===-1?'All':selectData[selNum].TWO}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sponsor-list" v-show="!selOpen">
                  <div class="w">
                    <el-row type="flex">
                      <el-col :xs="24" :sm="24" :md="6" v-for="(item,index) in sponsorData1.slice((pageCur-1)*pageCount,pageCur*pageCount)" :key="index">
                        <div class="sponsor-card" @click="sponsorBtn(item.SPONSOR_ID)">
                          <el-col :xs="10" :sm="10" :md="24">
                            <div class="logo">
                              <img :src="item.images" alt="">
                            </div>
                            <div class="sponsor-name">
                              <span class="name">{{item.ENNAME == '' ? item.CNNAME : item.ENNAME }}</span>
                              <span class="vip-ico"><img src="../../assets/images/Q/Q1/vip-icon.png" alt=""></span>
                            </div>
                            <div class="follow-btn">
                              <button @click.stop="changFollow(item.gz, item.SPONSOR_ID)">{{item.gz === 1?'Followed':'Follow'}}</button>
                            </div>
                          </el-col>
                          <el-col :xs="14" :sm="14" :md="24">
                            <div class="txt">
                              {{item.SHORTCONTENT}}
                            </div>
                          </el-col>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <el-pagination style="margin-top: 1rem" class="hidden-sm-and-down" background layout="prev, pager, next" prev-text="Prev" next-text="Next" hide-on-single-page :total="sponsorData1.length" :page-size="pageCount" :current-page="pageCur" @prev-click="prev()" @next-click="next()" @current-change="changepage"></el-pagination>
                </div>
                <transition name="mSel-fade">
                  <div class="m-sel-list hidden-md-and-up" v-show="selOpen">
                    <ul>
                      <li @click="selType(-1)" :class="selNum == -1?'is-active':''">All</li>
                      <li v-for="(item,index) in selectData" ref="typeItem" :key="index" @click="selType(index)" :class="selNum === index?'is-active':''">{{item.TWO}}</li>
                    </ul>
                    <div class="confirm-btn">
                      <button @click="confirmBtn">Confirm</button>
                    </div>
                  </div>
                </transition>
              </div>
            </el-main>
            <el-footer>
                <vfootere class="hidden-sm-and-down"></vfootere>
                <ivfootere class="hidden-md-and-up"></ivfootere>
            </el-footer>
        </el-container>
    </div>
</template>
<script>
import { delCollect, collectSponsor, noCollectSponsor, addCollect, jgtypelist } from '../../api'
import qs from 'qs'
export default {
  data () {
    return {
      selNum: -1,
      selOpen: false,
      // hereSel: 'All',
      selectData: [],
      // 每页显示条目个数
      pageCount: 12,
      // 当前页数
      pageCur: 1,
      sponsorData: [
      //   {
      //   IMAGE: require('../../assets/images/Q/Q1/logo.png'),
      //   CNNAME: 'ciytplus 城市+',
      //   SHORTCONTENT: '平台以服务城市国际化建设与对外交流合作为中心工作，实践“官方指导、民间运营”的新模式，围绕“国际化城市建设互联网新形态”的核心概念。'
      // },
      // {
      //   IMAGE: require('../../assets/images/Q/Q1/logo.png'),
      //   CNNAME: 'ciytplus 城市+',
      //   SHORTCONTENT: '平台以服务城市国际化建设与对外交流合作为中心工作，实践“官方指导、民间运营”的新模式，围绕“国际化城市建设互联网新形态”的核心概念。'
      // },
      // {
      //   IMAGE: require('../../assets/images/Q/Q1/logo.png'),
      //   CNNAME: 'ciytplus 城市+',
      //   SHORTCONTENT: '平台以服务城市国际化建设与对外交流合作为中心工作，实践“官方指导、民间运营”的新模式，围绕“国际化城市建设互联网新形态”的核心概念。'
      // },
      // {
      //   IMAGE: require('../../assets/images/Q/Q1/logo.png'),
      //   CNNAME: 'ciytplus 城市+',
      //   SHORTCONTENT: '平台以服务城市国际化建设与对外交流合作为中心工作，实践“官方指导、民间运营”的新模式，围绕“国际化城市建设互联网新形态”的核心概念。'
      // }
      // {
      //   image: require('../../assets/images/Q/Q1/logo.png'),
      //   name: 'ciytplus 城市+',
      //   txt: '平台以服务城市国际化建设与对外交流合作为中心工作，实践“官方指导、民间运营”的新模式，围绕“国际化城市建设互联网新形态”的核心概念。'
      // },
      // {
      //   image: require('../../assets/images/Q/Q1/logo.png'),
      //   name: 'ciytplus 城市+',
      //   txt: '平台以服务城市国际化建设与对外交流合作为中心工作，实践“官方指导、民间运营”的新模式，围绕“国际化城市建设互联网新形态”的核心概念。'
      // },
      // {
      //   image: require('../../assets/images/Q/Q1/logo.png'),
      //   name: 'ciytplus 城市+',
      //   txt: '平台以服务城市国际化建设与对外交流合作为中心工作，实践“官方指导、民间运营”的新模式，围绕“国际化城市建设互联网新形态”的核心概念。'
      // },
      // {
      //   image: require('../../assets/images/Q/Q1/logo.png'),
      //   name: 'ciytplus 城市+',
      //   txt: '平台以服务城市国际化建设与对外交流合作为中心工作，实践“官方指导、民间运营”的新模式，围绕“国际化城市建设互联网新形态”的核心概念。'
      // },
      // {
      //   image: require('../../assets/images/Q/Q1/logo.png'),
      //   name: 'ciytplus 城市+',
      //   txt: '平台以服务城市国际化建设与对外交流合作为中心工作，实践“官方指导、民间运营”的新模式，围绕“国际化城市建设互联网新形态”的核心概念。'
      // },
      // {
      //   image: require('../../assets/images/Q/Q1/logo.png'),
      //   name: 'ciytplus 城市+',
      //   txt: '平台以服务城市国际化建设与对外交流合作为中心工作，实践“官方指导、民间运营”的新模式，围绕“国际化城市建设互联网新形态”的核心概念。'
      // },
      // {
      //   image: require('../../assets/images/Q/Q1/logo.png'),
      //   name: 'ciytplus 城市+',
      //   txt: '平台以服务城市国际化建设与对外交流合作为中心工作，实践“官方指导、民间运营”的新模式，围绕“国际化城市建设互联网新形态”的核心概念。'
      // },
      // {
      //   image: require('../../assets/images/Q/Q1/logo.png'),
      //   name: 'ciytplus 城市+',
      //   txt: '平台以服务城市国际化建设与对外交流合作为中心工作，实践“官方指导、民间运营”的新模式，围绕“国际化城市建设互联网新形态”的核心概念。'
      // }
      ],
      sponsorData1: []
    }
  },
  inject: ['reload'],
  methods: {
    changFollow (e, id) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(userInfo.userName == 'yk'){
        this.$confirm('To log in, please click the button below', 'Tips', {
          confirmButtonText: 'Confirm',
          type: 'warning',
          center: true,
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false
        }).then(() => {
          this.$router.push('/LoginEN')
        })
      } else {
        let data = {
          USERID: userInfo.userId,
          SPONSORID: id
        }
        if (e === 1) {
          delCollect(qs.stringify(data)).then((res) => {
            if (res.data.result === 'ok') {
              this.reload()
            }
          })
        } else {
          addCollect(qs.stringify(data)).then((res) => {
            if (res.data.result === 'ok') {
              this.reload()
            }
          })
        }
      }
    },
    prev () {
      this.pageCur--
    },
    next () {
      this.pageCur++
    },
    sponsorBtn (id) {
      this.$router.push({
        name: 'cityQ4e',
        query: {
          id: id
        }
      })
    },
    btn1 () {
      this.$router.push('/ActivitiesEN')
    },
    btn2 () {
      this.$router.push('/activitylistEN')
    },
    btn3 () {
      this.$router.push('/sponsorlistEN')
    },
    btn4 () {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(userInfo.userName == 'yk'){
        this.$confirm('To log in, please click the button below', 'Tips', {
          confirmButtonText: 'Confirm',
          type: 'warning',
          center: true,
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false
        }).then(() => {
          this.$router.push('/LoginEN')
        })
      } else {
        this.$router.push('/createEN')
      }
    },
    btn4phone () {
      this.$message({
        type: 'info',
        message: 'Create an event on PC ',
        center: true
      })
    },
    confirmBtn () {
      this.selOpen = false
    },
    selType (e) {
      this.selNum = e
      if (this.selNum === -1) {
        this.sponsorData1 = this.sponsorData
      } else {
        let arr = []
        for (let i = 0; i < this.sponsorData.length; i++) {
          if (this.selectData[e].CLASSIFY == this.sponsorData[i].类型 || this.selectData[e].TWO == this.sponsorData[i].类型) {
            arr.push(this.sponsorData[i])
          }
        }
        this.sponsorData1 = arr
      }
      // this.hereSel = this.selectData[i].type
      // console.log(this.hereSel)
    },
    selListOpen () {
      this.selOpen = true
    },
	changepage(e){
	  this.pagecur = e
	}
  },
  mounted () {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo == undefined || userInfo == null) {
      this.$confirm('To log in, please click the button below', '提示', {
        confirmButtonText: 'Confirm',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false
      }).then(() => {
        this.$router.push('/LoginEN')
      })
    } else {
      jgtypelist().then((res) => {
        this.selectData = res.data
      })
      collectSponsor(userInfo.userId).then((res) => {
        this.sponsorData = this.sponsorData.concat(res.data)
        noCollectSponsor(userInfo.userId).then((res) => {
          this.sponsorData = this.sponsorData.concat(res.data)
          this.sponsorData1 = this.sponsorData
        })
      }).catch((error) => {
        // console.log(error)
        this.$message({
          showClose: true,
          message: 'Request failed. Please check your network or contact administrator',
          type: 'error',
          center: true
        })
      })
    }
  }
}
</script>
<style lang="less" scoped src="../../assets/css/CityQ3.less"></style>
<style lang="less">
.Q3-container {
  .funA-top{
    .content{
      .list{
        .btn{
          display: inline-block;
          padding: 0.15rem 0.5rem;
          background: #C1DB5C;
          cursor: pointer;
          span{
            font-size: 0.25rem;
            color: #434A3A;
          }
        }
      }
    }
  }
  .el-row--flex{
    flex-wrap: wrap;
  }
  .sponsor-list{
    .el-pagination{
      text-align: center
    }
    .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
      margin: 0;
      border-radius: 0;
      border :0.0185rem solid #e2e2e2;
      background :#fff;
      padding: 0 .24rem;
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active{
      background: #a5cd53;
      border: 0.0185rem solid #a5cd53;
    }
    .el-col{
      margin-top: 0.6rem;
    }
  }
}
</style>
